import React from 'react';
import { PropsWithChildren } from 'react';
import { cn } from '../../utils';

export const AdminLayout = (
  props: PropsWithChildren<{
    wrapperProps?: React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >;
    containerProps?: React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >;
  }>,
) => {
  return (
    <div
      {...props.wrapperProps}
      className={cn(
        'bg-slate-900 h-full min-h-screen font-mono',
        props.wrapperProps?.className,
      )}
    >
      <div
        className={cn(
          'max-w-2xl m-auto text-white p-10 flex flex-col gap-5',
          props.containerProps?.className,
        )}
      >
        {props.children}
      </div>
    </div>
  );
};
