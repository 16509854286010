exports.components = {
  "component---src-pages-401-tsx": () => import("./../../../src/pages/401.tsx" /* webpackChunkName: "component---src-pages-401-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-invoices-tsx": () => import("./../../../src/pages/account/invoices.tsx" /* webpackChunkName: "component---src-pages-account-invoices-tsx" */),
  "component---src-pages-account-licenses-tsx": () => import("./../../../src/pages/account/licenses.tsx" /* webpackChunkName: "component---src-pages-account-licenses-tsx" */),
  "component---src-pages-account-request-password-reset-tsx": () => import("./../../../src/pages/account/request-password-reset.tsx" /* webpackChunkName: "component---src-pages-account-request-password-reset-tsx" */),
  "component---src-pages-account-reset-password-tsx": () => import("./../../../src/pages/account/reset-password.tsx" /* webpackChunkName: "component---src-pages-account-reset-password-tsx" */),
  "component---src-pages-account-sign-in-to-qlab-tsx": () => import("./../../../src/pages/account/sign-in-to-qlab.tsx" /* webpackChunkName: "component---src-pages-account-sign-in-to-qlab-tsx" */),
  "component---src-pages-account-store-credit-tsx": () => import("./../../../src/pages/account/store-credit.tsx" /* webpackChunkName: "component---src-pages-account-store-credit-tsx" */),
  "component---src-pages-account-transactions-tsx": () => import("./../../../src/pages/account/transactions.tsx" /* webpackChunkName: "component---src-pages-account-transactions-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-code-of-conduct-tsx": () => import("./../../../src/pages/code-of-conduct.tsx" /* webpackChunkName: "component---src-pages-code-of-conduct-tsx" */),
  "component---src-pages-confirm-email-address-change-tsx": () => import("./../../../src/pages/confirm/email-address-change.tsx" /* webpackChunkName: "component---src-pages-confirm-email-address-change-tsx" */),
  "component---src-pages-confirm-new-email-address-tsx": () => import("./../../../src/pages/confirm/new-email-address.tsx" /* webpackChunkName: "component---src-pages-confirm-new-email-address-tsx" */),
  "component---src-pages-cookbook-tsx": () => import("./../../../src/pages/cookbook.tsx" /* webpackChunkName: "component---src-pages-cookbook-tsx" */),
  "component---src-pages-create-account-via-fancy-login-tsx": () => import("./../../../src/pages/create-account-via-fancy-login.tsx" /* webpackChunkName: "component---src-pages-create-account-via-fancy-login-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-education-tag-tsx": () => import("./../../../src/pages/education/tag.tsx" /* webpackChunkName: "component---src-pages-education-tag-tsx" */),
  "component---src-pages-educational-pricing-tsx": () => import("./../../../src/pages/educational-pricing.tsx" /* webpackChunkName: "component---src-pages-educational-pricing-tsx" */),
  "component---src-pages-fancy-login-ts": () => import("./../../../src/pages/fancy-login.ts" /* webpackChunkName: "component---src-pages-fancy-login-ts" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-invoice-print-tsx": () => import("./../../../src/pages/invoice-print.tsx" /* webpackChunkName: "component---src-pages-invoice-print-tsx" */),
  "component---src-pages-invoice-tsx": () => import("./../../../src/pages/invoice.tsx" /* webpackChunkName: "component---src-pages-invoice-tsx" */),
  "component---src-pages-learn-qlab-tsx": () => import("./../../../src/pages/learn-qlab.tsx" /* webpackChunkName: "component---src-pages-learn-qlab-tsx" */),
  "component---src-pages-license-tsx": () => import("./../../../src/pages/license.tsx" /* webpackChunkName: "component---src-pages-license-tsx" */),
  "component---src-pages-licensing-terms-tsx": () => import("./../../../src/pages/licensing-terms.tsx" /* webpackChunkName: "component---src-pages-licensing-terms-tsx" */),
  "component---src-pages-mailing-list-tsx": () => import("./../../../src/pages/mailing-list.tsx" /* webpackChunkName: "component---src-pages-mailing-list-tsx" */),
  "component---src-pages-manifest-tsx": () => import("./../../../src/pages/manifest.tsx" /* webpackChunkName: "component---src-pages-manifest-tsx" */),
  "component---src-pages-order-print-tsx": () => import("./../../../src/pages/order-print.tsx" /* webpackChunkName: "component---src-pages-order-print-tsx" */),
  "component---src-pages-order-tsx": () => import("./../../../src/pages/order.tsx" /* webpackChunkName: "component---src-pages-order-tsx" */),
  "component---src-pages-overview-tsx": () => import("./../../../src/pages/overview.tsx" /* webpackChunkName: "component---src-pages-overview-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-qclass-tsx": () => import("./../../../src/pages/qclass.tsx" /* webpackChunkName: "component---src-pages-qclass-tsx" */),
  "component---src-pages-qlab-remote-tsx": () => import("./../../../src/pages/qlab-remote.tsx" /* webpackChunkName: "component---src-pages-qlab-remote-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-sign-in-tsx": () => import("./../../../src/pages/sign-in.tsx" /* webpackChunkName: "component---src-pages-sign-in-tsx" */),
  "component---src-pages-site-license-pricing-tsx": () => import("./../../../src/pages/site-license-pricing.tsx" /* webpackChunkName: "component---src-pages-site-license-pricing-tsx" */),
  "component---src-pages-stats-dashboard-tsx": () => import("./../../../src/pages/stats-dashboard.tsx" /* webpackChunkName: "component---src-pages-stats-dashboard-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-upgrade-to-v-5-tsx": () => import("./../../../src/pages/upgrade-to-v5.tsx" /* webpackChunkName: "component---src-pages-upgrade-to-v-5-tsx" */),
  "component---src-pages-verify-email-ts": () => import("./../../../src/pages/verify-email.ts" /* webpackChunkName: "component---src-pages-verify-email-ts" */),
  "component---src-templates-cookbook-article-tsx": () => import("./../../../src/templates/cookbook-article.tsx" /* webpackChunkName: "component---src-templates-cookbook-article-tsx" */),
  "component---src-templates-cookbook-tag-page-tsx": () => import("./../../../src/templates/cookbook-tag-page.tsx" /* webpackChunkName: "component---src-templates-cookbook-tag-page-tsx" */),
  "component---src-templates-docs-tsx": () => import("./../../../src/templates/docs.tsx" /* webpackChunkName: "component---src-templates-docs-tsx" */),
  "component---src-templates-education-tag-page-tsx": () => import("./../../../src/templates/education-tag-page.tsx" /* webpackChunkName: "component---src-templates-education-tag-page-tsx" */),
  "component---src-templates-printable-docs-tsx": () => import("./../../../src/templates/printable-docs.tsx" /* webpackChunkName: "component---src-templates-printable-docs-tsx" */),
  "component---src-templates-release-notes-clean-tsx": () => import("./../../../src/templates/release-notes/clean.tsx" /* webpackChunkName: "component---src-templates-release-notes-clean-tsx" */),
  "component---src-templates-release-notes-tsx": () => import("./../../../src/templates/release-notes.tsx" /* webpackChunkName: "component---src-templates-release-notes-tsx" */)
}

