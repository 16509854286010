import * as React from 'react';

import RMarkdown, { Options } from 'react-markdown';
import classnames from 'classnames';
import rehypeRaw from 'rehype-raw';

import {
  createAnchorLinksInElement,
  mountAnchorLinksInElement,
} from './anchor-link';
import remarkGfm from 'remark-gfm';

type Props = {
  children: string;
  className?: string;
  prerendered?: boolean;
  textColor?: 'light' | 'dark';
  options?: Options;
};

/**
 * Renders a fragment of mardown passed as a string through its children. Can
 * either render raw markdown, or just be used to apply styles to
 * pre-prerendered HTML content. If passing HTML, set the `prerendered` prop.
 *
 * Adds a button to the right of all headings headings with an ID to copy a link
 * to that heading to the clipboard.
 */
export const ReactMarkdown = (props: Props) => {
  const {
    prerendered = false,
    children,
    textColor = 'light',
    className,
    options,
  } = props;

  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const element = ref?.current;
    if (element == null) {
      return;
    }

    createAnchorLinksInElement(element);
    mountAnchorLinksInElement(element, textColor);
  }, []);

  if (children == null) {
    return null;
  }

  const classes = classnames(
    'Markdown',
    `Markdown--${textColor}Text`,
    className,
  );

  if (!prerendered) {
    return (
      <RMarkdown
        className={classes}
        {...options}
        rehypePlugins={[
          rehypeRaw, // Enable raw HTML tags
          remarkGfm, // Enable github-flavored markdown
          ...(options?.rehypePlugins || []),
        ]}
      >
        {children}
      </RMarkdown>
    );
  }

  return (
    <div
      className={classes}
      dangerouslySetInnerHTML={{
        __html: children,
      }}
      ref={ref}
    />
  );
};
