import React from 'react';
import { cn } from '../utils/cn';

export const P = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  >,
) => <p {...props} className={cn('m-0', props.className)} />;

type HeadingProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
>;

const sharedHeadingClasses = 'font-bold';

export const H1 = (props: HeadingProps) => (
  <h1
    {...props}
    className={cn('text-4xl', sharedHeadingClasses, props.className)}
  />
);

export const H2 = (props: HeadingProps) => (
  <h2
    {...props}
    className={cn('text-3xl', sharedHeadingClasses, props.className)}
  />
);

export const H3 = (props: HeadingProps) => (
  <h3
    {...props}
    className={cn('text-2xl', sharedHeadingClasses, props.className)}
  />
);

export const H4 = (props: HeadingProps) => (
  <h4
    {...props}
    className={cn('text-xl', sharedHeadingClasses, props.className)}
  />
);
