import React, { useEffect, useState } from 'react';
import { shuffle } from 'lodash';
import { TestimonialType } from '../../../types/src/testimonial';
import { RotateCw } from 'lucide-react';

import { GatsbyImage } from 'gatsby-plugin-image';

export type TestimonialProps = {
  testimonial: TestimonialType;
  onNext?: () => void;
  size?: number;
};

export const Testimonial = ({
  testimonial,
  onNext,
  size = 120,
}: TestimonialProps) => {
  const hasNext = Boolean(onNext);

  return (
    <div
      className="flex gap-10 p-8 cursor-pointer max-w-4xl m-auto group"
      onClick={onNext}
    >
      <div className="flex sm:flex-row flex-col gap-10">
        {testimonial.image && (
          <div>
            <div className="rounded-full overflow-hidden">
              <GatsbyImage
                image={testimonial.image.sizes.gatsbyImageData}
                objectFit="cover"
                style={{ width: size, height: size }}
                alt={testimonial.author}
              />
            </div>
          </div>
        )}

        <div className="flex-1">
          <blockquote className="flex flex-col gap-2">
            <p className="text-xl">
              &ldquo;
              {testimonial.content}
              &rdquo;
            </p>
            <footer>
              <cite> — {testimonial.author}</cite>
            </footer>
          </blockquote>
        </div>
      </div>

      {hasNext && (
        <div className="sm:opacity-0 opacity-100 group-hover:opacity-100 transition-all">
          <RotateCw />
        </div>
      )}
    </div>
  );
};

type TestimonialsProps = {
  testimonials: TestimonialType[];
};

export const Testimonials = (props: TestimonialsProps) => {
  const { testimonials: _testimonials } = props;
  const [index, setIndex] = useState(0);
  const [testimonials, setTestimonials] = useState<TestimonialType[]>([]);

  const handleNext = () => {
    setIndex((index + 1) % testimonials.length);
  };

  useEffect(() => {
    setTestimonials(shuffle(_testimonials));
    setIndex(Math.floor(Math.random() * _testimonials.length));
  }, []);

  const testimonial = testimonials[index];

  if (!testimonial) {
    return null;
  }

  return (
    <Testimonial
      testimonial={testimonial}
      onNext={testimonials.length > 1 ? handleNext : undefined}
    />
  );
};
