import React from 'react';

export type ExternalLinkProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
> & {
  newWindow?: boolean;
};

export const ExternalLink = ({
  newWindow,
  ...restProps
}: ExternalLinkProps) => (
  <a
    {...(newWindow ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
    {...restProps}
  />
);
