import React from 'react';
import { cn } from '../utils/cn';
import { cva, type VariantProps } from 'class-variance-authority';

const messageVariants = cva('px-4 py-3 rounded-sm', {
  variants: {
    variant: {
      default:
        'bg-slate-100/20 border border-slate-400 text-slate-100 text-base',
      negative: 'bg-red-600/40 border border-red-500 text-base',
      positive: 'bg-green-600/40 border border-green-500 text-base',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

export interface MessageProps
  extends React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >,
    VariantProps<typeof messageVariants> {
  asChild?: boolean;
}

export const Message = React.forwardRef<HTMLDivElement, MessageProps>(
  ({ className, variant, ...props }, ref) => {
    return (
      <div
        className={cn(messageVariants({ variant, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);

Message.displayName = 'Message';
