import React from 'react';
import { ReactMarkdown } from './react-markdown';
import { cn } from '../utils/cn';
import { H1, H2, P } from './typography';

export enum ChangeTypeEnum {
  FIXED = 'fixed',
  ADDED = 'added',
  CHANGED = 'changed',
}

type ReleaseNoteChangeType = {
  type: ChangeTypeEnum;
  major?: boolean;
  description: string;
};

export type ReleaseNoteChangeProps = {
  change: ReleaseNoteChangeType;
  bg?: string;
};

export const ReleaseNoteLabel = ({
  major,
  type,
  bg = 'bg-slate-700',
}: {
  major?: boolean;
  bg?: string;
  type: ChangeTypeEnum;
}) => (
  <div className="inline-flex w-[7em]">
    <div>
      <div
        className={cn(
          'uppercase font-bold text-sm p-1 px-2 rounded flex items-center gap-1',
          bg,
        )}
      >
        {major && <span>&#9733;</span>}
        {type}
      </div>
    </div>
  </div>
);

export const ReleaseNoteChange = ({
  change,
  bg = 'bg-slate-700',
}: ReleaseNoteChangeProps) => {
  const { major, type, description } = change;
  return (
    <div className="flex">
      <ReleaseNoteLabel major={Boolean(major)} type={type} bg={bg} />

      <div className="flex-1">
        <ReactMarkdown options={{ components: { p: P } }}>
          {description}
        </ReactMarkdown>
      </div>
    </div>
  );
};

const extractType = (className = ''): ChangeTypeEnum | undefined => {
  let type;

  switch (className) {
    case ChangeTypeEnum.ADDED:
      type = ChangeTypeEnum.ADDED;
      break;
    case ChangeTypeEnum.CHANGED:
      type = ChangeTypeEnum.CHANGED;
      break;
    case ChangeTypeEnum.FIXED:
      type = ChangeTypeEnum.FIXED;
      break;
  }

  return type;
};

const extractVersion = (content = ''): string => {
  const version = content.split(' - ')[0];
  return version;
};

const hasStar = (content = ''): boolean =>
  content.includes('★') || content.includes('&#9733;');

const StarsText = () => (
  <P className="opacity-75">&#9733; Stars denote major new features.</P>
);

export const ReleaseNotesHtml = ({
  html,
  colorMap,
  title,
}: {
  html: string;
  colorMap?: Record<ChangeTypeEnum, string>;
  title: string;
}) => {
  const includesMajorChanges = hasStar(html);

  return (
    <div className="flex flex-col gap-5">
      <H1>{title}</H1>
      {includesMajorChanges && <StarsText />}
      <ReactMarkdown
        className="flex flex-col gap-5"
        options={{
          components: {
            h2: ({ children }) => {
              const version = extractVersion(children?.toString());
              return <H2 className="!m-0 !text-3xl">{version}</H2>;
            },
            span: (props) => {
              const { className, children, node } = props;

              // Pull type from the className
              const type = extractType(className);

              if (type) {
                const content = children?.toString();
                return (
                  <ReleaseNoteLabel
                    type={type}
                    bg={colorMap?.[type]}
                    major={hasStar(content)}
                  />
                );
              }

              // Apply styles for spans with the 'button' class
              if (className === 'button') {
                return (
                  <span className="p-[2px] font-bold rounded-sm border">
                    {children}
                  </span>
                );
              }

              // Otherwise leave it alone
              return <span {...node?.properties}>{children}</span>;
            },
            p: ({ children }) => {
              if (Array.isArray(children)) {
                return (
                  <div className="grid grid-cols-[7em_auto]">
                    <span>{children.slice(0, 1)}</span>
                    <span>{children.slice(1)}</span>
                  </div>
                );
              }

              return <>{children}</>;
            },
          },
        }}
      >
        {html}
      </ReactMarkdown>
    </div>
  );
};

export const ReleaseNoteChanges = ({
  changes,
  title,
  colorMap,
}: {
  title?: string;
  colorMap?: Record<ChangeTypeEnum, string>;
  changes?: ReleaseNoteChangeType[];
  html?: string;
}) => {
  return (
    <div className="flex flex-col gap-5">
      {title && <H2>{title}</H2>}
      {changes?.map((change, index) => (
        <ReleaseNoteChange
          key={index}
          change={change}
          bg={colorMap?.[change.type]}
        />
      ))}
    </div>
  );
};

export type ReleaseNoteListProps = {
  releaseNotes: {
    categoryName?: string;
    changes: ReleaseNoteChangeType[];
  }[];
  colorMap?: Record<ChangeTypeEnum, string>;
  title: string;
};

export const ReleaseNoteList = ({
  releaseNotes,
  colorMap,
  title,
}: ReleaseNoteListProps) => {
  const includesMajorChanges = releaseNotes
    .flatMap((noteCategory) => noteCategory.changes || [])
    .some((note) => note.major);

  return (
    <div className="flex flex-col gap-5">
      <H1>{title}</H1>

      {includesMajorChanges && <StarsText />}

      {releaseNotes.map((noteCategory, index) => (
        <ReleaseNoteChanges
          key={index}
          colorMap={colorMap}
          changes={noteCategory.changes || []}
          title={noteCategory.categoryName}
        />
      ))}
    </div>
  );
};
