import * as log from 'app/log';
import { withRetries } from 'utils/retry';
import { Honeybadger } from '@honeybadger-io/react';
import { Notice } from '@honeybadger-io/core/build/src/types';

type HoneybadgerExtras = Partial<Notice>;

let honeybadger: Honeybadger | undefined | null;

// call this with Honeybadger in onClientEntry
export function init(honeybadgerInstance: Honeybadger) {
  if (honeybadger != null) {
    throw new Error('error reporter already initialized');
  }
  log.info('initializing error reporter', honeybadgerInstance);
  honeybadger = honeybadgerInstance;
}

export function notify(
  error: Error | string,
  extras?: HoneybadgerExtras | null,
) {
  if (ENV.BUILD_ENV === 'production' || ENV.BUILD_ENV === 'staging') {
    withRetries({
      delayMs: 500,
      maxRetries: 6,
      description: 'error report to honeybadger',
      fn: () => {
        if (honeybadger == null) {
          throw new Error(
            `Honeybadger not ready. Error not reported: ${JSON.stringify(error)}`,
          );
        }

        if (!extras) {
          extras = {};
        }

        if (!extras.context) {
          extras.context = {};
        }

        const autoContext = {
          logTail: log.dumpTail(50),
        };

        extras.context = Object.assign({}, extras.context, autoContext);

        if (error instanceof Error && extras.context.name == null) {
          extras.context.name = error.name;
        }

        honeybadger.notify(error, extras);
      },
    });
  } else {
    log.warn(error);
  }
}
export function setContext(context: { [key: string]: any }) {
  withRetries({
    delayMs: 500,
    maxRetries: 6,
    description: 'setting honeybadger error reporting context',
    fn: () => {
      if (honeybadger == null) {
        throw new Error(
          `Context not set, error reporter not configured: ${JSON.stringify(context)}`,
        );
      }
      honeybadger.setContext(context);
    },
  });
}

export const clear = () => honeybadger?.clear();

if (typeof window !== 'undefined') {
  window.__testErrorReporter = function () {
    notify(new Error('This is a test of the frontend error reporter.'));
  };
}
