import React, { ReactNode } from 'react';
import { ExternalToast, toast } from 'sonner';
import { Button } from '../components';
import { X } from 'lucide-react';

export const toastError = (
  message: string | ReactNode,
  options: ExternalToast & {
    dismissible?: boolean;
  } = {},
) =>
  toast.custom(
    (t) => (
      <div className="bg-slate-900 text-white rounded-lg p-4 flex gap-3 justify-between">
        <div className="flex gap-3">
          <div className="w-5 h-5 rounded-full flex items-center justify-center font-bold bg-red-600 text-white">
            !
          </div>
          <div className="text-sm flex-1">{message}</div>
        </div>

        {options.dismissible && (
          <div>
            <Button
              onClick={() => toast.dismiss(t)}
              className="bg-transparent hover:bg-white/5 w-8 h-8 p-0"
            >
              <X className="w-4 h-4" />
            </Button>
          </div>
        )}
      </div>
    ),
    options,
  );
