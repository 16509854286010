import React from 'react';
import { cn } from '../utils/cn';

type Props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const classes =
  'inline-flex items-center justify-center py-[0.65em] px-[1em] font-bold text-white rounded whitespace-nowrap bg-button-base hover:bg-button-hover';

export const Button = (props: Props) => {
  return <button {...props} className={cn(classes, props.className)} />;
};
